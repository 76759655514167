import React, { useState } from 'react'
import card from '../../assets/logo-header.png'
import { Button, TextField, Typography } from '@mui/material'
import { resetUserPassword } from '../../api/api'
import { formatBody } from './utils'

const initialFormInputs = {
  password: '',
  repeatPassword: '',
}
const initialFormErrors = {
  password: false,
  repeatPassword: false,
}

export default function ResetPassword({}) {
  const [formInputs, setFormInputs] = useState(initialFormInputs)
  const [formErrors, setFormErrors] = useState(initialFormErrors)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('token')

  const handleInputChange = (key, value) => {
    const newValues = { ...formInputs }
    newValues[key] = value
    setFormInputs(newValues)
    const newErrors = { ...formErrors }
    newErrors[key] = false
    setFormErrors(newErrors)
  }

  const hasFormErrors = () => {
    const newErrors = { ...formErrors }
    Object.keys(formErrors).map((e) => (newErrors[e] = !formInputs[e]))
    newErrors['repeatPassword'] =
      formInputs.password && formInputs.password !== formInputs.repeatPassword
    setFormErrors(newErrors)
    return Object.keys(formErrors).some((e) => newErrors[e])
  }

  const handleSubmit = async () => {
    if (!hasFormErrors()) {
      try {
        const response = await resetUserPassword(formatBody(formInputs, token))
      } catch (error) {
        console.error('Error while resetting password:', error)
      }
    }
  }

  return (
    <div
      style={{
        minHeight: '100%',
        maxHeight: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 150px',
        }}
      >
        <Typography
          style={{
            color: 'rgb(22 22 23 / 38%)',
            textAlign: 'center',
            padding: '15px',
          }}
          variant='h4'
        >
          Restablecer contraseña
        </Typography>
        <TextField
          variant='outlined'
          type='password'
          label='Nueva constraseña'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.password}
          onChange={(e) => handleInputChange('password', e.target?.value)}
          error={formErrors.password}
          helperText={formErrors.password ? 'Este campo es requerido' : ''}
        />
        <TextField
          variant='outlined'
          label='Repetir Contraseña'
          type='password'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.repeatPassword}
          disabled={!formInputs.password}
          onChange={(e) => handleInputChange('repeatPassword', e.target?.value)}
          error={formErrors.repeatPassword}
          helperText={
            formErrors.repeatPassword ? 'Las contraseñas no coinciden' : ''
          }
        />
        <Typography variant='body1' color={'gray'}>
          Debe volver a iniciar sesion con la nueva contraseña establecida.
        </Typography>
        <Button
          size='large'
          variant='contained'
          color='primary'
          style={{ width: '100%' }}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </div>
      <div
        style={{
          backgroundColor: 'rgba(22, 22, 23)',
          placeContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img height={300} src={card}></img>
      </div>
    </div>
  )
}
