import React, { useEffect, useState } from 'react'
import card from '../../assets/logo-header.png'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  Avatar,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  initialFormErrors,
  initialFormInputs,
  transformNewUserData,
  transformUserData,
} from './utils'
import ReactInputMask from 'react-input-mask'
import {
  createNewAccount,
  getUserAccount,
  logout,
  updateAccount,
} from '../../api/api'
import ChangePasswordModal from '../../components/changePasswordModal'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

export default function UserProfile({ createAccount }) {
  const [openModal, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [formInputs, setFormInputs] = useState(initialFormInputs)
  const [formErrors, setFormErrors] = useState(initialFormErrors)
  const [disabledForm, setDisabledForm] = useState(!createAccount)
  const navigate = useNavigate()

  const handleLoadData = async () => {
    if (!createAccount) {
      try {
        const response = await getUserAccount()
        const newFormInputs = {
          ...formInputs,
          ...(response.data ? response.data : {}),
        }
        setFormInputs(newFormInputs)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    handleLoadData()
  }, [])

  const handleInputChange = (key, value) => {
    const newValues = { ...formInputs }
    newValues[key] = value
    setFormInputs(newValues)
    const newErrors = { ...formErrors }
    newErrors[key] = false
    setFormErrors(newErrors)
  }

  const hasFormErrors = () => {
    const newErrors = { ...formErrors }
    Object.keys(formErrors).map((e) => (newErrors[e] = !formInputs[e]))
    newErrors['password'] = createAccount && !formInputs.password
    newErrors['repeatPassword'] =
      createAccount &&
      formInputs.password &&
      formInputs.password !== formInputs.repeatPassword
    setFormErrors(newErrors)
    return Object.keys(formErrors).some((e) => newErrors[e])
  }

  const handleSubmit = async () => {
    if (!hasFormErrors()) {
      try {
        const newUser = createAccount
          ? transformNewUserData(formInputs)
          : transformUserData(formInputs)
        createAccount
          ? await createNewAccount(newUser).then((response) => {})
          : await updateAccount(newUser)
        createAccount ? navigate('/login') : setDisabledForm(true)
      } catch (error) {
        console.error('Error while creating user:', error)
      }
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseSession = async () => {
    handleClose()
    try {
      await logout()
      Cookies?.remove('authToken')
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      style={{
        minHeight: '100%',
        maxHeight: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <ChangePasswordModal open={openModal} setOpen={setOpenModal} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 150px',
        }}
      >
        {createAccount && (
          <Typography
            style={{
              color: 'rgb(22 22 23 / 38%)',
              textAlign: 'center',
              padding: '15px',
            }}
            variant='h4'
          >
            Crear nuevo usuario
          </Typography>
        )}
        {!createAccount && (
          <>
            <Typography
              style={{
                position: 'relative',
                fontSize: '100px',
                color: '#ffffff',
                textAlign: 'center',
                background: 'rgb(76 76 77)',
                borderRadius: '100%',
                width: 'fit-content',
                padding: '15px 20px',
              }}
            >
              {formInputs.name ? formInputs.name[0]?.toUpperCase() : 'No'}
              {formInputs.lastname
                ? formInputs.lastname[0]?.toUpperCase()
                : 'User'}
              <IconButton
                style={{ position: 'absolute', cursor: 'pointer' }}
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Typography>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  setDisabledForm(false)
                  handleClose()
                }}
              >
                Editar perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenModal(true)
                  handleClose()
                }}
              >
                Cambiar Contraseña
              </MenuItem>
              <MenuItem onClick={handleCloseSession}>Cerrar Sesión</MenuItem>
            </Menu>
          </>
        )}
        <TextField
          variant='outlined'
          label='Nombre'
          value={formInputs.name}
          margin='dense'
          fullWidth
          color='primary'
          onChange={(e) => handleInputChange('name', e.target?.value)}
          error={formErrors.name}
          helperText={formErrors.name ? 'Este campo es requerido' : ''}
          disabled={disabledForm}
        />
        <TextField
          variant='outlined'
          label='Apellido'
          value={formInputs.lastname}
          margin='dense'
          fullWidth
          color='primary'
          onChange={(e) => handleInputChange('lastname', e.target?.value)}
          error={formErrors.lastname}
          helperText={formErrors.lastname ? 'Este campo es requerido' : ''}
          disabled={disabledForm}
        />
        <TextField
          variant='outlined'
          label='Email'
          value={formInputs.email}
          margin='dense'
          fullWidth
          color='primary'
          onChange={(e) => handleInputChange('email', e.target?.value)}
          error={formErrors.email}
          helperText={formErrors.email ? 'Este campo es requerido' : ''}
          disabled={!createAccount}
        />
        {createAccount && (
          <>
            <TextField
              variant='outlined'
              label='Contraseña'
              type='password'
              margin='dense'
              fullWidth
              color='primary'
              value={formInputs.password}
              onChange={(e) => handleInputChange('password', e.target?.value)}
              error={formErrors.password}
              helperText={formErrors.password ? 'Este campo es requerido' : ''}
            />
            <TextField
              variant='outlined'
              label='Repetir Contraseña'
              type='password'
              margin='dense'
              fullWidth
              color='primary'
              value={formInputs.repeatPassword}
              disabled={!formInputs.password}
              onChange={(e) =>
                handleInputChange('repeatPassword', e.target?.value)
              }
              error={formErrors.repeatPassword}
              helperText={
                formErrors.repeatPassword ? 'Las contraseñas no coinciden' : ''
              }
            />
            <Button
              size='large'
              variant='contained'
              color='primary'
              style={{ width: '100%' }}
              onClick={handleSubmit}
            >
              Enviar
            </Button>
          </>
        )}
        {!createAccount && !disabledForm && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              gap: '10px',
              width: '-webkit-fill-available',
            }}
          >
            <Button
              size='large'
              variant='outlined'
              color='primary'
              onClick={() => setDisabledForm(true)}
            >
              Cancelar
            </Button>
            <Button
              size='large'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
            >
              Guardar Cambios
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          backgroundColor: 'rgba(22, 22, 23)',
          placeContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img height={300} src={card}></img>
      </div>
    </div>
  )
}
