import React, { useEffect, useRef, useState, useCallback } from 'react'
import { fabric } from 'fabric'
import ImagesPanel from '../../components/imagesPanel'
import { getTest, postBlurImage } from '../../api/api'
import {
  Autocomplete,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import { v4 as uuidv4 } from 'uuid'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export default function App() {
  const [images, setImages] = useState([])
  const [anonymizedImages, setAnonymizedImages] = useState([])
  const [fabricCanvas, setFabricCanvas] = useState(null)
  const [currentGroup, setCurrentGroup] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openSettings, setOpenSettings] = useState(false)
  const [isEnableMove, setIsEnableMove] = useState(null)
  const [isEnableResize, setIsEnableResize] = useState(null)
  const [isEnableDrawing, setIsEnableDrawing] = useState(null)
  const [value, setValue] = useState(0)
  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const open = Boolean(anchorElMenu)
  const [activeSelection, setActiveSelection] = useState(false)

  const options = [
    { value: 1, label: 'Difuminado' },
    { value: 2, label: 'Difuminado Duro' },
    { value: 3, label: 'Difuminado Suave' },
    { value: 4, label: 'Sal y Pimienta' },
    { value: 5, label: 'Bloqueo' },
    { value: 6, label: 'Pixelado' },
  ]

  useEffect(() => {
    const canvas = new fabric.Canvas('canvas', {
      backgroundColor: '#e3e3e3',
      selection: false,
      renderOnAddRemove: true,
    })
    fabric.Object.prototype.transparentCorners = false
    fabric.Object.prototype.cornerStyle = 'circle'
    fabric.Object.prototype.cornerSize = 5

    canvas.setDimensions({ width: '100%', height: '100%' }, { cssOnly: true })
    setFabricCanvas(canvas)
    setIsEnableDrawing(false)
    setIsEnableMove(false)
    setIsEnableResize(false)

    return () => canvas.dispose()
  }, [])

  const setDefaultValues = useCallback(() => {
    setIsEnableMove(false)
    setIsEnableResize(false)
    currentGroup.selectable = false
  }, [currentGroup, setIsEnableMove, setIsEnableResize])

  useEffect(() => {
    if (!fabricCanvas) return

    const handleMouseDown = (event) => {
      if (!currentGroup) return
      if (currentGroup.selectable) return
      if (!isEnableDrawing) return
      setDefaultValues()
      let pointer = fabricCanvas.getPointer(event.e)
      let objects = currentGroup.getObjects()
      const baseObject = objects[0]
      if (currentGroup.aCoords.tl.x >= pointer.x) {
        pointer.x = currentGroup.aCoords.tl.x
      } else if (currentGroup.aCoords.br.x <= pointer.x) {
        pointer.x = currentGroup.aCoords.br.x
      }

      // Adjust pointerMove.y based on boundary conditions
      if (currentGroup.aCoords.tl.y >= pointer.y) {
        pointer.y = currentGroup.aCoords.tl.y
      } else if (currentGroup.aCoords.br.y <= pointer.y) {
        pointer.y = currentGroup.aCoords.br.y
      }
      const rect = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        originX: 'left',
        originY: 'top',
        width: 1,
        height: 1,
        fill: 'rgba(39, 50, 245, 0.36)',
        stroke: 'black',
        strokeWidth: 1,
        selectable: false,
      })
      currentGroup.addWithUpdate(rect)
      fabricCanvas.renderAll()

      const onMouseMove = (e) => {
        if (!currentGroup) return
        if (currentGroup.selectable) return
        if (!isEnableDrawing) return
        setDefaultValues()
        let pointerMove = fabricCanvas.getPointer(e.e)

        // Adjust pointerMove.x based on boundary conditions
        if (currentGroup.aCoords.tl.x >= pointerMove.x) {
          pointerMove.x = currentGroup.aCoords.tl.x
        } else if (currentGroup.aCoords.br.x <= pointerMove.x) {
          pointerMove.x = currentGroup.aCoords.br.x - 2
        }

        // Adjust pointerMove.y based on boundary conditions
        if (currentGroup.aCoords.tl.y >= pointerMove.y) {
          pointerMove.y = currentGroup.aCoords.tl.y
        } else if (currentGroup.aCoords.br.y <= pointerMove.y) {
          pointerMove.y = currentGroup.aCoords.br.y - 2
        }
        rect.set({
          width: Math.round(pointerMove.x - pointer.x),
          height: Math.round(pointerMove.y - pointer.y),
        })
        currentGroup.setCoords()
        fabricCanvas.requestRenderAll()
      }

      const onMouseUp = () => {
        fabricCanvas.off('mouse:move', onMouseMove)
        fabricCanvas.off('mouse:up', onMouseUp)
      }

      fabricCanvas.on('mouse:move', onMouseMove)
      fabricCanvas.on('mouse:up', onMouseUp)
    }

    fabricCanvas.on('mouse:down', handleMouseDown)

    return () => {
      fabricCanvas.off('mouse:down', handleMouseDown)
    }
  }, [
    fabricCanvas,
    currentGroup,
    isEnableDrawing,
    isEnableMove,
    isEnableResize,
    setDefaultValues,
  ])

  const selectImage = (selectedImage, isAnonymized = false) => {
    if (!fabricCanvas) return
    if (currentImage && !isAnonymized) {
      const updatedImage = images.filter((img) => img.id === currentImage.id)[0]
      updatedImage['settings'] = {
        ...updatedImage?.settings,
        group: currentGroup,
      }
    }
    fabricCanvas.clear()
    fabricCanvas.backgroundColor = '#e3e3e3'
    setCurrentImage(selectedImage)

    fabric.Image.fromURL(URL.createObjectURL(selectedImage.image), (img) => {
      const scaleFactor = Math.min(
        fabricCanvas.width / img.width,
        fabricCanvas.height / img.height,
      )
      img.set({
        scaleX: scaleFactor,
        scaleY: scaleFactor,
      })
      let group
      if (!selectedImage.settings?.group) {
        group = new fabric.Group([img], {
          angle: 0,
          selectable: false,
        })
      } else {
        group = selectedImage.settings?.group
      }
      setCurrentGroup(group)
      fabricCanvas.add(group)
    })
  }

  const makeRequest = () => {
    if (!currentGroup) return
    // Retrieve all objects in the group
    let objects = currentGroup.getObjects()
    if (objects.length === 0 || !(objects[0] instanceof fabric.Image)) {
      console.log('No image found as first object.')
      return
    }
    if (objects.length < 2) {
      console.log('Not enough objects in the group to compare positions.')
      return
    }
    // Assume the first object is the reference frame
    const baseObject = objects[0]
    const boxes = objects.slice(1)
    // Log details of each object relative to the first object
    const boundingBoxes = boxes.map((obj) => ({
      x1: Math.round(
        (obj.aCoords.tl.x - baseObject.aCoords.tl.x) / baseObject.scaleX,
      ),
      y1: Math.round(
        (obj.aCoords.tl.y - baseObject.aCoords.tl.y) / baseObject.scaleY,
      ),
      x2: Math.round(
        (obj.left - baseObject.left + obj.width * obj.scaleX) /
          baseObject.scaleX,
      ),
      y2: Math.round(
        (obj.top - baseObject.top + obj.height * obj.scaleY) /
          baseObject.scaleY,
      ),
    }))
    postBlurImage(
      currentImage.image,
      boundingBoxes,
      currentImage.settings.intensity,
      currentImage.settings.type,
    ).then((response) => {
      const newAnonymizedImages = [
        ...anonymizedImages,
        {
          id: uuidv4(),
          referenceId: currentImage.id,
          image: response.data,
          settings: {
            group: null,
            type: 1,
            intensity: 50,
          },
        },
      ]
      setAnonymizedImages(newAnonymizedImages)
    })
  }

  const enableGroupMove = () => {
    if (!fabricCanvas) return
    if (!currentGroup) return
    let controls = ['tl', 'tr', 'br', 'bl', 'ml', 'mt', 'mr', 'mb', 'mtr']
    controls.forEach((control) => {
      currentGroup.setControlVisible(control, false)
    })
    if (isEnableDrawing) {
      setIsEnableDrawing(false)
      currentGroup.selectable = true
      currentGroup.lockMovementX = false
      currentGroup.lockMovementY = false
    } else if (isEnableResize) {
      setIsEnableResize(false)
      currentGroup.selectable = true
      currentGroup.lockMovementX = false
      currentGroup.lockMovementY = false
    } else {
      fabricCanvas.discardActiveObject()
      currentGroup.selectable
        ? (currentGroup.selectable = false)
        : (currentGroup.selectable = true)
    }

    setIsEnableMove(true)
    fabricCanvas.renderAll()
  }

  const enableGroupResize = () => {
    if (!fabricCanvas) return
    if (!currentGroup) return
    let controls = ['tl', 'tr', 'br', 'bl', 'ml', 'mt', 'mr', 'mb']
    currentGroup.setControlVisible('mtr', false)
    controls.forEach((control) => {
      currentGroup.setControlVisible(control, true)
    })

    setIsEnableDrawing(false)
    currentGroup.lockMovementX = true
    currentGroup.lockMovementY = true
    if (isEnableMove) {
      setIsEnableMove(false)
    } else {
      fabricCanvas.discardActiveObject()
      currentGroup.selectable
        ? (currentGroup.selectable = false)
        : (currentGroup.selectable = true)
    }

    setIsEnableResize(true)
    fabricCanvas.renderAll()
  }

  const enableDrawing = () => {
    if (!fabricCanvas) return
    if (!currentGroup) return
    if (isEnableDrawing) {
      setIsEnableDrawing(false)
      return
    }
    let controls = ['tl', 'tr', 'br', 'bl', 'ml', 'mt', 'mr', 'mb', 'mtr']
    controls.forEach((control) => {
      currentGroup.setControlVisible(control, false)
    })
    currentGroup.selectable = false
    setIsEnableMove(false)
    setIsEnableResize(false)
    setIsEnableDrawing(true)
    fabricCanvas.discardActiveObject()
    fabricCanvas.renderAll()
  }

  function zoomIn() {
    if (!fabricCanvas) return
    fabricCanvas.setZoom(fabricCanvas.getZoom() * 1.1)
  }

  function zoomOut() {
    if (!fabricCanvas) return
    fabricCanvas.setZoom(fabricCanvas.getZoom() * 0.9)
  }

  function compareValueToOption(option, value) {
    return option.type === value.type
  }

  const handleUploadImage = (imagesUploaded) => {
    const newImages = imagesUploaded.map((image) => ({
      ...image,
      settings: {
        group: null,
        type: 1,
        intensity: 50,
      },
    }))
    setImages([...images, ...newImages])
  }

  const onChangeSettings = (key, value) => {
    const updatedImage = currentImage
    updatedImage['settings'] = {
      ...updatedImage.settings,
      [key]: value,
    }
    setCurrentImage(updatedImage)
  }

  const handleDeleteImages = (id, isAnonymized = false) => {
    isAnonymized
      ? setImages(anonymizedImages.filter((x) => x.id !== id))
      : setImages(images.filter((x) => x.id !== id))
  }

  const clearCanvas = () => {
    fabricCanvas.clear()
    fabricCanvas.backgroundColor = '#e3e3e3'
    setCurrentImage(null)
  }

  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElMenu(null)
  }

  const handleDownloadZip = async (images, setSelectedImages = () => {}) => {
    const zip = new JSZip()

    for (const image of images) {
      const url = URL.createObjectURL(image.image)
      const response = await fetch(url)
      const blob = await response.blob()
      zip.file(`${image.id}.${image?.image.type?.split('/')[1]}`, blob, {
        binary: true,
      })
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'imagenesAnonimizadas.zip')
    })
    setActiveSelection(false)
    setSelectedImages([])
  }

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <ImagesPanel
        images={images}
        anonymizedImages={anonymizedImages}
        handleUploadImage={handleUploadImage}
        onDeleteImage={handleDeleteImages}
        setCurrentImage={selectImage}
        clearCanvas={clearCanvas}
        value={value}
        setValue={setValue}
        activeSelection={activeSelection}
        handleDownloadZip={handleDownloadZip}
      />
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridTemplateRows: '1fr 90px',
          rowGap: '15px',
          padding: '15px',
        }}
      >
        <canvas
          id='canvas'
          style={{
            margin: '0 auto',
            border: '2px solid',
          }}
        />
        <div
          style={{
            display: 'flex',
            backgroundColor: 'rgba(22, 22, 23)',
            borderRadius: '8px',
            padding: '10px 20px',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip
              title='Opciones de anonimización'
              disableHoverListener={openSettings}
            >
              <Typography style={{ color: 'white' }}>
                Tipo de Ofuscación
              </Typography>
              <Autocomplete
                style={{
                  background: 'white',
                  borderRadius: '5px',
                  width: '200px',
                }}
                disablePortal
                id='ofuscacion'
                options={options}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={
                  options.filter(
                    (x) => x.value === currentImage?.settings?.type,
                  )[0]
                }
                size='small'
                onChange={(e, newValue) => {
                  onChangeSettings('type', newValue?.value || '')
                }}
                onFocus={(e) => e.stopPropagation()}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                disableClearable
              />
            </Tooltip>
            <Tooltip
              title='Opciones de anonimización'
              disableHoverListener={openSettings}
            >
              <Typography style={{ color: 'white' }}>Intensidad</Typography>
              <Slider
                size='small'
                value={currentImage?.settings?.intensity}
                aria-label='Small'
                valueLabelDisplay='auto'
                step={10}
                onChange={(e) => {
                  onChangeSettings('intensity', e.target.value)
                }}
                style={{ width: '200px' }}
              />
            </Tooltip>
            <Tooltip title='Acercar imagen'>
              <IconButton
                onClick={() => {
                  zoomIn()
                }}
              >
                <ZoomInIcon fontSize='large' htmlColor='#FFFFFF' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Alejar imagen'>
              <IconButton
                onClick={() => {
                  zoomOut()
                }}
              >
                <ZoomOutIcon fontSize='large' htmlColor='#FFFFFF' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Mover imagen'>
              <IconButton onClick={() => enableGroupMove()}>
                <OpenWithIcon fontSize='large' htmlColor='#FFFFFF' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Ajustar tamaño'>
              <IconButton
                onClick={() => {
                  enableGroupResize()
                }}
              >
                <AspectRatioIcon fontSize='large' htmlColor='#FFFFFF' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Marcar regiones de interes'>
              <IconButton
                onClick={() => {
                  enableDrawing()
                }}
              >
                <HighlightAltIcon fontSize='large' htmlColor='#FFFFFF' />
              </IconButton>
            </Tooltip>
          </div>
          {value == 0 ? (
            <Button
              variant='contained'
              onClick={makeRequest}
              style={{
                backgroundColor: '#9500ff',
                height: '50px',
              }}
            >
              Guardar Cambios
            </Button>
          ) : (
            <>
              <Button
                variant='contained'
                onClick={handleClick}
                style={{
                  backgroundColor: '#9500ff',
                  height: '50px',
                }}
              >
                Descargar Imágenes
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorElMenu}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => setActiveSelection(true)}>
                  Seleccionar imágenes a descargar.
                </MenuItem>
                <MenuItem onClick={() => handleDownloadZip(anonymizedImages)}>
                  Descargar todas las imágenes.
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
