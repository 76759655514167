import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AutomaticAnonymization from '../pages/AutomaticAnonymization'
import CustomAnonymization from '../pages/CustomAnonymization'
import Home from '../pages/Home'
import { Wrapper } from './routesStyles'
import UserProfile from '../pages/Profile'
import Login from '../pages/Login'
import ResetPassword from '../pages/ResetPassword'
import RestorePassword from '../pages/RestorePassword'
import { useCookie } from '../components/CookieHook'

export default function ApplicationRoutes({}) {
  const [cookieValue, setCookieValue] = useCookie('authToken')

  const ProtectedRoute = ({ children }) => {
    if (!cookieValue) {
      return <Navigate to='/login' replace />
    }
    return children
  }

  const LoginRoutes = ({ children }) => {
    if (cookieValue) {
      return <Navigate to='/' replace />
    }
    return children
  }

  return (
    <Wrapper>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route
          path='login'
          element={
            <LoginRoutes>
              <Login />
            </LoginRoutes>
          }
        />
        <Route
          path='resetPassword'
          element={
            <LoginRoutes>
              <ResetPassword />
            </LoginRoutes>
          }
        />
        <Route
          path='resetUserPassword'
          element={
            <LoginRoutes>
              <RestorePassword />
            </LoginRoutes>
          }
        />
        <Route
          path='createAccount'
          element={
            <LoginRoutes>
              <UserProfile createAccount={true} />
            </LoginRoutes>
          }
        />
        <Route
          path='AutomaticAnonymization'
          element={
            <ProtectedRoute>
              <AutomaticAnonymization />
            </ProtectedRoute>
          }
        />
        <Route
          path='CustomAnonymization'
          element={
            <ProtectedRoute>
              <CustomAnonymization />
            </ProtectedRoute>
          }
        />
        <Route
          path='MyProfile'
          element={
            <ProtectedRoute>
              <UserProfile createAccount={false} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Wrapper>
  )
}
