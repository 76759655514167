import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

export const useCookie = (cookieName) => {
  const [cookieValue, setCookieValue] = useState(() => Cookies.get(cookieName))

  useEffect(() => {
    const handleCookieChange = () => {
      const newValue = Cookies.get(cookieName)
      if (newValue !== cookieValue) {
        setCookieValue(newValue)
      }
    }

    const cookieObserver = new MutationObserver(handleCookieChange)
    const config = { attributes: true, childList: true, subtree: true }

    cookieObserver.observe(document, config)

    return () => {
      cookieObserver.disconnect()
    }
  }, [cookieName, cookieValue])

  return [cookieValue, setCookieValue]
}
