export const initialFormInputs = {
  password: '',
  newPassword: '',
  repeatPassword: '',
}
export const initialFormErrors = {
  password: false,
  newPassword: false,
  repeatPassword: false,
}

export const transformChangePasswordData = (data) => ({
  password: data.password,
  new_password: data.newPassword,
})
