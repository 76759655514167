import React, { useState, useEffect } from 'react'
import { Alert, Snackbar } from '@mui/material'

export default function SnackbarError({}) {
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [severity, setSeverity] = useState('')

  useEffect(() => {
    window.addEventListener('apiError', handleApiError)

    return () => {
      window.removeEventListener('apiError', handleApiError)
    }
  }, [])

  const handleApiError = (event) => {
    setErrorMessage(event.detail.message)
    setSeverity(event.detail.severity)
    setOpen(!!event.detail.message)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ marginTop: '75px' }}
      disableWindowBlurListener
    >
      <Alert severity={severity}>{errorMessage}</Alert>
    </Snackbar>
  )
}
