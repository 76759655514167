import { Button } from '@mui/material'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(22, 22, 23);
`

const Image = styled.img`
  height: 164px;
  width: 195px;
  object-fit: cover;
`
const Uploader = styled.input`
  display: none;
`
const UploadButton = styled(Button)`
  width: 400px;
  min-height: 50px;
  background-color: #9500ff !important;
`
export { Container, Image, Uploader, UploadButton }
