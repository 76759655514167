import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import {
  initialFormErrors,
  initialFormInputs,
  transformChangePasswordData,
} from './utils'
import { updatePassword } from '../../api/api'

export default function ChangePasswordModal({ open, setOpen }) {
  const [formInputs, setFormInputs] = useState(initialFormInputs)
  const [formErrors, setFormErrors] = useState(initialFormErrors)

  const handleClose = () => {
    setOpen(false)
    setFormInputs(initialFormInputs)
  }

  const handleInputChange = (key, value) => {
    const newValues = { ...formInputs }
    newValues[key] = value
    setFormInputs(newValues)
    const newErrors = { ...formErrors }
    newErrors[key] = false
    setFormErrors(newErrors)
  }

  const hasFormErrors = () => {
    const newErrors = { ...formErrors }
    Object.keys(formErrors).map((e) => (newErrors[e] = !formInputs[e]))
    newErrors['repeatPassword'] =
      formInputs.password &&
      formInputs.newPassword !== formInputs.repeatPassword
    setFormErrors(newErrors)
    return Object.keys(formErrors).some((e) => newErrors[e])
  }

  const handleSubmit = async () => {
    if (!hasFormErrors()) {
      try {
        const newUser = transformChangePasswordData(formInputs)
        await updatePassword(newUser).then((response) => {})
        handleClose()
      } catch (error) {
        console.error('Error while creating user:', error)
      }
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Cambiar contraseña</DialogTitle>
      <DialogContent>
        <TextField
          variant='outlined'
          label='Contraseña actual'
          type='password'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.password}
          onChange={(e) => handleInputChange('password', e.target?.value)}
          error={formErrors.password}
          helperText={formErrors.password ? 'Este campo es requerido' : ''}
        />
        <TextField
          variant='outlined'
          label='Nueva contraseña'
          type='password'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.newPassword}
          onChange={(e) => handleInputChange('newPassword', e.target?.value)}
          error={formErrors.newPassword}
          helperText={formErrors.newPassword ? 'Este campo es requerido' : ''}
        />
        <TextField
          variant='outlined'
          label='Repetir nueva contraseña'
          type='password'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.repeatPassword}
          disabled={!formInputs.password}
          onChange={(e) => handleInputChange('repeatPassword', e.target?.value)}
          error={formErrors.repeatPassword}
          helperText={
            formErrors.repeatPassword ? 'Las contraseñas no coinciden' : ''
          }
        />
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <Button variant='outlined' color='primary' onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
