import React, { useState } from 'react'
import card from '../../assets/logo-header.png'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
  Avatar,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { login } from '../../api/api'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

const initialFormInputs = {
  email: '',
  password: '',
}
const initialFormErrors = {
  email: false,
  password: false,
}

export default function Login({}) {
  const [formInputs, setFormInputs] = useState(initialFormInputs)
  const [formErrors, setFormErrors] = useState(initialFormErrors)
  const navigate = useNavigate()

  const handleInputChange = (key, value) => {
    const newValues = { ...formInputs }
    newValues[key] = value
    setFormInputs(newValues)
    const newErrors = { ...formErrors }
    newErrors[key] = false
    setFormErrors(newErrors)
  }

  const hasFormErrors = () => {
    const newErrors = { ...formErrors }
    Object.keys(formErrors).map((e) => (newErrors[e] = !formInputs[e]))
    setFormErrors(newErrors)
    return Object.keys(formErrors).some((e) => newErrors[e])
  }

  const handleSubmit = async () => {
    if (!hasFormErrors()) {
      try {
        const response = await login(formInputs)
        Cookies?.set('authToken', response?.data?.access_token, {
          secure: true,
          sameSite: 'Strict',
        })
        navigate('/')
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <div
      style={{
        minHeight: '100%',
        maxHeight: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 150px',
        }}
      >
        <Typography
          style={{
            color: 'rgb(22 22 23 / 38%)',
            textAlign: 'center',
            padding: '15px',
          }}
          variant='h4'
        >
          Iniciar sesión
        </Typography>
        <TextField
          variant='outlined'
          label='Correo electrónico'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.email}
          onChange={(e) => handleInputChange('email', e.target?.value)}
          error={formErrors.email}
          helperText={formErrors.email ? 'Este campo es requerido' : ''}
        />
        <TextField
          variant='outlined'
          label='Contraseña'
          type='password'
          margin='dense'
          fullWidth
          color='primary'
          value={formInputs.password}
          onChange={(e) => handleInputChange('password', e.target?.value)}
          error={formErrors.password}
          helperText={formErrors.password ? 'Este campo es requerido' : ''}
        />
        <Button
          size='large'
          variant='contained'
          color='primary'
          style={{ width: '100%' }}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
        <Link href='resetPassword' underline='hover'>
          Olvidé mi contraseña
        </Link>
      </div>
      <div
        style={{
          backgroundColor: 'rgba(22, 22, 23)',
          placeContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img height={300} src={card}></img>
      </div>
    </div>
  )
}
