import { Button } from '@mui/material'
import styled from 'styled-components'


const Image = styled.img`
  height: 164px;
  width: 195px;
  object-fit: cover;
`
const Uploader = styled.input`
  display: none;
`
const UploadButton = styled.div`
  border-radius: 20px;
  min-width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  place-items: center;
  background: #e6e6e6;
`

export { Image, Uploader, UploadButton }
