import React, { useState } from 'react'
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tab,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DownloadIcon from '@mui/icons-material/Download'
import { Container, Image, UploadButton, Uploader } from './imagesPanelStyle'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { v4 as uuidv4 } from 'uuid'

export default function ImagesPanel({
  images,
  handleUploadImage,
  onDeleteImage,
  setCurrentImage,
  anonymizedImages,
  clearCanvas,
  value,
  setValue,
  activeSelection,
  handleDownloadZip,
}) {
  const openAttachment = () => document.getElementById('upload-images').click()
  const [selectedImages, setSelectedImages] = useState([])

  const handleImageSelected = (imagesList) => {
    const arrayImages = [...imagesList]
    const newImages = arrayImages.map((image) => {
      const id = uuidv4()
      const imageSize = image?.size / 1024 / 1024
      if (imageSize > 25) {
        console.log(
          'The file you selected exceeds file size limit of 25MB.',
          'error',
        )
        return
      }
      return { id, image }
    })
    handleUploadImage(newImages)
  }

  const handleDownloadImage = (blob, filename) => {
    const a = document.createElement('a')
    const url = URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const handleAddImage = (item) => {
    if (selectedImages.some((img) => img.id === item.id)) {
      const newImages = selectedImages.filter((img) => img.id !== item.id)
      setSelectedImages(newImages)
    } else {
      const newImages = [...selectedImages, item]
      setSelectedImages(newImages)
    }
  }

  return (
    <Container>
      <TabContext value={value}>
        <TabList
          onChange={(_, tab) => {
            setValue(tab)
            clearCanvas()
          }}
          textColor='secondary'
          indicatorColor='secondary'
        >
          <Tab
            label='Imágenes Subidas'
            value={0}
            style={{ color: '#FFFFFF' }}
          />
          <Tab
            label='Imágenes anonimizadas'
            value={1}
            style={{ color: '#FFFFFF' }}
          />
        </TabList>
        <TabPanel
          value={0}
          style={{
            overflow: 'hidden',
            display: value === 0 ? 'flex' : 'none',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <ImageList
            sx={{ width: 400, height: '100%' }}
            cols={2}
            gap={10}
            rowHeight={164}
          >
            {images.map((item) => (
              <ImageListItem key={item.id}>
                <Image
                  src={`${URL.createObjectURL(item.image)}`}
                  alt={item.image.name}
                  loading='lazy'
                  onClick={() => setCurrentImage(item)}
                />
                <ImageListItemBar
                  style={{ background: 'black' }}
                  title={item.image.name}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label={`info about ${item.image.name}`}
                      // onClick={() => onDeleteImage(item.id, true)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          <Uploader
            type='file'
            id={'upload-images'}
            accept='.jpg, .png, .jpeg'
            onChange={(e) => handleImageSelected(e.target.files)}
            multiple
          />
          <UploadButton
            variant='contained'
            onClick={openAttachment}
            style={{ marginTop: '15px' }}
          >
            AGREGAR IMÁGENES
          </UploadButton>
        </TabPanel>
        <TabPanel
          value={1}
          style={{
            overflow: 'hidden',
            display: value === 1 ? 'flex' : 'none',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <ImageList
            sx={{ width: 400, height: '100%' }}
            cols={2}
            gap={10}
            rowHeight={164}
          >
            {anonymizedImages.map((item) => (
              <ImageListItem key={item.id}>
                <Image
                  src={`${URL.createObjectURL(item.image)}`}
                  alt={item.image.name}
                  loading='lazy'
                  onClick={() => setCurrentImage(item, true)}
                />
                <ImageListItemBar
                  style={{ background: 'black' }}
                  title={item.image.name ? item.image.name : ''}
                  actionIcon={
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <IconButton
                        sx={{ color: 'white' }}
                        aria-label={`info about ${item.image.name}`}
                        // onClick={() => onDeleteImage(item.id)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                      <IconButton
                        sx={{ color: 'white' }}
                        aria-label={`info about ${item.image.name}`}
                        onClick={() => handleDownloadImage(item.image, item.id)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      {activeSelection && (
                        <div>
                          {selectedImages.some((img) => img.id === item.id) ? (
                            <IconButton
                              sx={{ color: 'white' }}
                              aria-label={`info about ${item.image.name}`}
                              onClick={() => handleAddImage(item)}
                            >
                              <CheckBoxIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ color: 'white' }}
                              aria-label={`info about ${item.image.name}`}
                              onClick={() => handleAddImage(item)}
                            >
                              <CheckBoxOutlineBlankIcon />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </div>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          {activeSelection && (
            <UploadButton
              variant='contained'
              onClick={() =>
                handleDownloadZip(selectedImages, setSelectedImages)
              }
              style={{ marginTop: '15px' }}
            >
              DESCARGAR SELECCIÓN
            </UploadButton>
          )}
        </TabPanel>
      </TabContext>
    </Container>
  )
}
