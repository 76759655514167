import React, { useState } from 'react'
import {
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Autocomplete,
  Popover,
  Slider,
  TextField,
  Tooltip,
  Typography,
  Tab,
  CircularProgress,
  Backdrop,
  MenuItem,
  Menu,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddIcon from '@mui/icons-material/Add'
import { Image, UploadButton, Uploader } from './automaticAnonymizationStyles'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import { postAutomaticAnonymizeImages } from '../../api/api'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { v4 as uuidv4 } from 'uuid'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export default function AutomaticAnonymization({}) {
  const [value, setValue] = useState(0)
  const [base64Images, setBase64Images] = useState([])
  const [images, setImages] = useState([])
  const [imagesProcessed, setImagesProcessed] = useState([])
  const [loading, setLoading] = useState(false)

  const [currentIntensity, setCurrentIntensity] = useState(50)
  const [currentType, setCurrentType] = useState(1)
  const [currentThreshold, setCurrentThreshold] = useState(20)

  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const open = Boolean(anchorElMenu)
  const [activeSelection, setActiveSelection] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElMenu(null)
  }

  const handleDownloadZip = async (images, setSelectedImages = () => {}) => {
    const zip = new JSZip()

    for (const image of images) {
      const url = URL.createObjectURL(image.image)
      const response = await fetch(url)
      const blob = await response.blob()
      zip.file(`${image.id}.${image?.image.type?.split('/')[1]}`, blob, {
        binary: true,
      })
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'imagenesAnonimizadas.zip')
    })
    setActiveSelection(false)
    setSelectedImages([])
  }
  const handleDownloadImage = (blob, filename) => {
    const a = document.createElement('a')
    const url = URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const handleAddImage = (item) => {
    if (selectedImages.some((img) => img.id === item.id)) {
      const newImages = selectedImages.filter((img) => img.id !== item.id)
      setSelectedImages(newImages)
    } else {
      const newImages = [...selectedImages, item]
      setSelectedImages(newImages)
    }
  }

  const openAttachment = () => document.getElementById('upload-images').click()

  const options = [
    { value: 1, label: 'Difuminado' },
    { value: 2, label: 'Difuminado Duro' },
    { value: 3, label: 'Difuminado Suave' },
    { value: 4, label: 'Sal y Pimienta' },
    { value: 5, label: 'Bloqueo' },
    { value: 6, label: 'Pixelado' },
  ]

  const handleUploadImage = (imagesUploaded) => {
    const newImages = imagesUploaded.map((image) => ({
      ...image,
      settings: {
        group: null,
        type: 1,
        intensity: 50,
      },
    }))
    setImages([...images, ...newImages])
  }

  const handleImageSelected = (imagesList) => {
    const arrayImages = [...imagesList]
    const newImages = arrayImages.map((image) => {
      const id = uuidv4()
      const imageSize = image?.size / 1024 / 1024
      if (imageSize > 25) {
        console.log(
          'The file you selected exceeds file size limit of 25MB.',
          'error',
        )
        return
      }
      return { id, image }
    })
    handleUploadImage(newImages)
  }

  const onDeleteImage = (id) => {
    const newImages = images.filter((image) => image.id !== id)
    setImages(newImages)
  }

  const handleFileInputChange = (event) => {
    const files = event.target.files
    const imagesArray = []
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const base64 = e.target.result.split(',')[1]
        imagesArray.push(base64)
        if (imagesArray.length === files.length) {
          setBase64Images(imagesArray)
        }
      }
      reader.readAsDataURL(files[i])
    }
  }

  const makeRequest = () => {
    setValue(1)
    setLoading(true)
    let apiCalls = images.map((image) => {
      return postAutomaticAnonymizeImages(
        [image.image],
        currentIntensity,
        currentType,
        currentThreshold,
      ).then((response) => {
        const img = {
          id: uuidv4(),
          image: response.data,
        }
        setImagesProcessed((imgs) => [...imgs, img])
      })
    })
    Promise.all(apiCalls)
      .then((responses) => {
        setLoading(false)
        console.log('Todas las respuestas de la API:', responses)
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error en alguna de las llamadas a la API:', error)
      })
  }

  const onChangeIntensity = (intensityValue) => {
    setCurrentIntensity(intensityValue)
  }
  const onChangeType = (typeValue) => {
    setCurrentType(typeValue)
  }
  const onChangeThreshold = (typeValue) => {
    setCurrentThreshold(typeValue)
  }

  return (
    <>
      <>
        <Backdrop
          style={{
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
          }}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id='my_gradient'
                x1='0%'
                y1='0%'
                x2='0%'
                y2='100%'
              >
                <stop offset='0%' stopColor='#e01cd5' />
                <stop offset='100%' stopColor='#1CB5E0' />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            size={90}
            sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
          />
        </Backdrop>
      </>
      <div
        style={{
          padding: '10px',
          display: 'grid',
          gridTemplateRows: '1fr 90px',
          rowGap: '15px',
          height: '100%',
        }}
      >
        <div>
          <TabContext value={value}>
            <TabList
              onChange={(_, tab) => {
                setValue(tab)
              }}
              textColor='secondary'
              indicatorColor='secondary'
            >
              <Tab
                label='Imágenes Subidas'
                value={0}
                style={{ color: '#FFFFFF', background: 'rgba(22, 22, 23)' }}
              />
              <Tab
                label='Imágenes anonimizadas'
                value={1}
                style={{ color: '#FFFFFF', background: 'rgba(22, 22, 23)' }}
              />
            </TabList>
            <TabPanel
              value={0}
              style={{
                background: '#e3e3e3',
                display: value === 0 ? 'flex' : 'none',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '10px 5px',
                height: '93%',
              }}
            >
              <ImageList
                sx={{ width: '100%' }}
                cols={6}
                gap={8}
                rowHeight={164}
              >
                {images.map((item) => (
                  <ImageListItem key={item.id}>
                    <Image
                      src={`${URL.createObjectURL(item.image)}`}
                      alt={item.image.name}
                      loading='lazy'
                      style={{ width: '100%' }}
                      // onClick={() => setCurrentImage(item)}
                    />
                    <ImageListItemBar
                      style={{ background: 'black' }}
                      title={item.id}
                      actionIcon={
                        <IconButton
                          sx={{ color: 'white' }}
                          aria-label={`info about ${item.image.name}`}
                          onClick={() => onDeleteImage(item.id)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </TabPanel>
            <TabPanel
              value={1}
              style={{
                background: '#e3e3e3',
                display: value === 1 ? 'flex' : 'none',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '10px 5px',
                height: '93%',
              }}
            >
              <ImageList
                sx={{ width: '100%' }}
                cols={6}
                gap={8}
                rowHeight={164}
              >
                {imagesProcessed.map((item) => (
                  <ImageListItem key={item.id}>
                    <Image
                      src={`${URL.createObjectURL(item.image)}`}
                      alt={item.id}
                      loading='lazy'
                      style={{ width: '100%' }}
                    />
                    <ImageListItemBar
                      style={{ background: 'black' }}
                      title={item.id}
                      actionIcon={
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <IconButton
                            sx={{ color: 'white' }}
                            aria-label={`info about ${item.image.name}`}
                            onClick={() =>
                              handleDownloadImage(item.image, item.id)
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                          {activeSelection && (
                            <div>
                              {selectedImages.some(
                                (img) => img.id === item.id,
                              ) ? (
                                <IconButton
                                  sx={{ color: 'white' }}
                                  aria-label={`info about ${item.image.name}`}
                                  onClick={() => handleAddImage(item)}
                                >
                                  <CheckBoxIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  sx={{ color: 'white' }}
                                  aria-label={`info about ${item.image.name}`}
                                  onClick={() => handleAddImage(item)}
                                >
                                  <CheckBoxOutlineBlankIcon />
                                </IconButton>
                              )}
                            </div>
                          )}
                        </div>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </TabPanel>
          </TabContext>
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: 'rgba(22, 22, 23)',
            borderRadius: '8px',
            padding: '10px 20px',
            gap: '10px',
            justifyContent: value === 0 ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {value === 0 ? (
            <>
              {' '}
              <div style={{ display: 'flex', gap: '10px' }}>
                <Tooltip title='Método de anonimización'>
                  <Typography color='rgb(227, 227, 227)'>
                    Tipo de Ofuscación
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id='ofuscacion'
                    options={options}
                    sx={{ width: 300 }}
                    style={{
                      backgroundColor: 'rgb(227, 227, 227',
                      borderRadius: '5px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={options.filter((x) => x.value === currentType)[0]}
                    size='small'
                    onChange={(e, newValue) => {
                      onChangeType(newValue?.value || '')
                    }}
                    onFocus={(e) => e.stopPropagation()}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value
                    }}
                    disableClearable
                  />
                </Tooltip>
                <Tooltip title='Intensidad de aninimización'>
                  <Typography color='rgb(227, 227, 227)'>Intensidad</Typography>
                  <Slider
                    size='small'
                    value={currentIntensity}
                    aria-label='Small'
                    valueLabelDisplay='auto'
                    step={10}
                    sx={{ width: 300 }}
                    onChange={(e) => {
                      onChangeIntensity(e.target.value)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Seguridad de Deteccion'>
                  <Typography color='rgb(227, 227, 227)'>Confianza</Typography>
                  <Slider
                    size='small'
                    value={currentThreshold}
                    aria-label='Small'
                    valueLabelDisplay='auto'
                    step={1}
                    sx={{ width: 300 }}
                    onChange={(e) => {
                      onChangeThreshold(e.target.value)
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                <Uploader
                  type='file'
                  id={'upload-images'}
                  accept='.jpg, .png, .jpeg'
                  onChange={(e) => handleImageSelected(e.target.files)}
                  multiple
                />
                <Button
                  onClick={openAttachment}
                  style={{ background: '#9500ff', height: '50px' }}
                  variant='contained'
                  endIcon={<AddIcon />}
                >
                  AGREGAR IMÁGENES
                </Button>
                <Button
                  style={{ background: '#9500ff', height: '50px' }}
                  variant='contained'
                  component='label'
                  onClick={makeRequest}
                >
                  Anonimizar
                </Button>
              </div>
            </>
          ) : (
            <>
              {activeSelection && (
                <Button
                  style={{
                    backgroundColor: '#9500ff',
                    height: '50px',
                  }}
                  variant='contained'
                  onClick={() =>
                    handleDownloadZip(selectedImages, setSelectedImages)
                  }
                >
                  DESCARGAR SELECCIÓN
                </Button>
              )}
              <Button
                variant='contained'
                onClick={handleClick}
                style={{
                  backgroundColor: '#9500ff',
                  height: '50px',
                }}
              >
                Descargar Imágenes
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorElMenu}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => setActiveSelection(true)}>
                  Seleccionar imágenes a descargar.
                </MenuItem>
                <MenuItem onClick={() => handleDownloadZip(imagesProcessed)}>
                  Descargar todas las imágenes.
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    </>
  )
}
