import axios from 'axios'
import Cookies from 'js-cookie'
const baseURL = getBaseUrl()

function getBaseUrl() {
  let val = process.env.REACT_APP_API_URL
  if (val === undefined || val === null) {
    return 'https://tesis-anonymizer.web.elasticloud.uy/proy-grad'
  }
  return val + '/proy-grad'
}

/**********************
 * AXIOS INTERCEPTORS *
 **********************/

function refreshToken() {
  return axios.post(`${baseURL}/auth/refresh`)
}

// Request interceptor
axios.interceptors.request.use(
  function (config) {
    const authToken = Cookies.get('authToken') || ''
    config.headers['Authorization'] = `Bearer ${authToken}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

// Response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    const customError = {
      message: response?.data?.message ? response?.data?.message : '',
      severity: 'success',
    }
    window.dispatchEvent(new CustomEvent('apiError', { detail: customError }))
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const customError = {
      message: error.response?.data?.error
        ? error.response?.data?.error
        : 'Ha ocurrido un error procesando su solicitud.',
      severity: 'error',
    }
    if (error.response?.status === 401) {
      Cookies?.remove('authToken')
      window.location.href = 'login'
      customError['message'] = 'Su sesión ha expirado.'
    }
    window.dispatchEvent(new CustomEvent('apiError', { detail: customError }))
    return Promise.reject(error)
  },
)

/*****************
 * ANONYMIZATION *
 *****************/
function postBlurImage(img, boundingBoxes, blurLevel, blurType) {
  const formData = new FormData()
  formData.append('strength_level', blurLevel)
  formData.append('type', blurType)
  formData.append('image', img)
  formData.append('bounding_boxes', JSON.stringify(boundingBoxes))
  return axios.post(`${baseURL}/blur_image`, formData, { responseType: 'blob' })
}

function postAutomaticAnonymizeImages(images, blurLevel, blurType, threshold) {
  const formData = new FormData()
  formData.append('strength_level', blurLevel)
  formData.append('type', blurType)
  formData.append('threshold', threshold)
  images.map((image) => {
    formData.append('images', image)
  })
  return axios.post(`${baseURL}/automatic_blur`, formData, {
    responseType: 'blob',
  })
}

/***********
 * LOGIN  *
 ***********/
function login(body) {
  return axios.post(`${baseURL}/auth/login`, body)
}

function logout(token) {
  return axios.delete(`${baseURL}/auth/logout`)
}

/***********
 * USERS  *
 ***********/

function resetPassword(body) {
  return axios.post(`${baseURL}/users/reset-password-request`, body)
}

function resetUserPassword(body) {
  return axios.post(`${baseURL}/users/reset-password`, body)
}

function createNewAccount(body) {
  return axios.post(`${baseURL}/users/register`, body)
}

function updateAccount(body, token) {
  return axios.put(`${baseURL}/users`, body)
}

function updatePassword(body, token) {
  return axios.put(`${baseURL}/users/change-password`, body)
}

function getUserAccount(token) {
  return axios.get(`${baseURL}/users`)
}

export {
  postBlurImage,
  postAutomaticAnonymizeImages,
  login,
  logout,
  resetPassword,
  resetUserPassword,
  createNewAccount,
  updateAccount,
  updatePassword,
  getUserAccount,
}
