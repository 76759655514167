export const initialFormInputs = {
  name: '',
  lastname: '',
  email: '',
  password: '',
  repeatPassword: '',
}
export const initialFormErrors = {
  name: false,
  lastname: false,
  email: false,
  password: false,
  repeatPassword: false,
}

export const transformNewUserData = (data) => ({
  name: data.name,
  lastname: data.lastname,
  email: data.email,
  password: data.password,
})

export const transformUserData = (data) => ({
  name: data.name,
  lastname: data.lastname,
})
