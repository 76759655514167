import styled from 'styled-components'

const MainSection = styled.div`
  background-color: #000000;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MainTitle = styled.p`
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-weight: 200;
  display: flex;
  align-items: center;
  font-size: 50px;
`

const CardContainer = styled.div`
  padding: 10%;
  display: flex;
  flex-direction: column;
`

const CardLeft = styled.div`
  display: flex;
  width: 50%;
  align-self: flex-start;
`
const CardRight = styled.div`
  display: flex;
  width: 50%;
  align-self: flex-end;
`

const TextContainerRight = styled.div`
  margin-left: 50px;
`
const TextContainerLeft = styled.div`
  margin-right: 50px;
`

const Itemize = styled.div`
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #090d10;
  position: relative;
  margin-right: 10px;
`

const Title = styled.p`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 20px;
`

const Description = styled.p`
  font-weight: 600;
  font-family: Roboto, sans-serif;
  font-size: 40px;
  line-height: normal;
`

const DemoSection = styled.div`
  margin-top: 200px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  gap: 50px;
`

const Info = styled.div`
  background-color: #000000;
  border: 2px solid;
  border-color: #57006b;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
`

const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: #ffffff;
`

export {
  MainSection,
  MainTitle,
  CardContainer,
  CardLeft,
  CardRight,
  TextContainerRight,
  TextContainerLeft,
  Itemize,
  Title,
  Description,
  DemoSection,
  Info,
  Text,
}
