import React from 'react'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined'
import img1 from '../../assets/1.png'
import img2 from '../../assets/2.png'
import img3 from '../../assets/3.png'
import logo from '../../assets/logo-header.png'
import {
  CardContainer,
  CardLeft,
  CardRight,
  DemoSection,
  Description,
  Info,
  Itemize,
  MainSection,
  MainTitle,
  Text,
  TextContainerLeft,
  TextContainerRight,
  Title,
} from './homeStyles'

const Home = () => {
  return (
    <>
      <MainSection>
        <figure>
          <img src={logo} height={'300px'} width={'300px'} />
        </figure>
        <MainTitle>ANONIMYZER</MainTitle>
        <DemoSection>
          <Info>
            <ImageSearchIcon style={{ color: '#FFFFFF', fontSize: '40px' }} />
            <Text> Selecciona tus imágenes</Text>
          </Info>
          <Info>
            <ChecklistRtlIcon style={{ color: '#FFFFFF', fontSize: '40px' }} />
            <Text> Ajusta los parámetros</Text>
          </Info>
          <Info>
            <CloudSyncIcon style={{ color: '#FFFFFF', fontSize: '40px' }} />
            <Text> Procesa</Text>
          </Info>
          <Info style={{ borderColor: '#022fc2' }}>
            <WallpaperOutlinedIcon
              style={{ color: '#FFFFFF', fontSize: '40px' }}
            />
            <Text> Obtén tu imagen anonimizada</Text>
          </Info>
        </DemoSection>
      </MainSection>
      <CardContainer>
        <CardLeft>
          <figure>
            <img src={img1} height={'300px'} width={'300px'} />
          </figure>
          <TextContainerRight>
            <Title>
              <Itemize />
              AUTOMATIZACIÓN
            </Title>
            <Description>Procesamiento de</Description>
            <Description>múltiples imágenes</Description>
            <Description>al mismo tiempo</Description>
          </TextContainerRight>
        </CardLeft>
        <CardRight>
          <TextContainerLeft>
            <Title>
              <Itemize />
              OPCIONES
            </Title>
            <Description>Distintas formas de</Description>
            <Description>preservar</Description>
            <Description>la identidad</Description>
          </TextContainerLeft>
          <figure>
            <img src={img2} height={'300px'} width={'300px'} />
          </figure>
        </CardRight>
        <CardLeft>
          <figure>
            <img src={img3} height={'300px'} width={'300px'} />
          </figure>
          <TextContainerRight>
            <Title>
              <Itemize />
              PERSONALIZAR
            </Title>
            <Description>Establece los</Description>
            <Description>parámetros que mejor</Description>
            <Description>se adapten</Description>
            <Description>a tus necesidades</Description>
          </TextContainerRight>
        </CardLeft>
      </CardContainer>
    </>
  )
}

export default Home
