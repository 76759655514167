import React, { useEffect } from 'react'
import logo from '../../assets/logo-header.png'
import { HeaderContainer, Menu, MenuItem, Divider } from './headerSyles'
import { useCookie } from '../CookieHook'

export default function Header() {
  const [cookieValue, setCookieValue] = useCookie('authToken')

  return (
    <HeaderContainer>
      <figure>
        <img src={logo} height={'50px'} />
      </figure>
      <Menu>
        <MenuItem to={'/'}>Inicio</MenuItem>
        {cookieValue && (
          <>
            <MenuItem to={'AutomaticAnonymization'}>
              Anonimización Automática
            </MenuItem>
            <MenuItem to={'CustomAnonymization'}>Anonimización Manual</MenuItem>
            <Divider orientation='vertical' />
            <MenuItem to={'MyProfile'}>Mi Perfil</MenuItem>
          </>
        )}
        {!cookieValue && (
          <>
            <Divider orientation='vertical' />
            <MenuItem to={'login'}>Iniciar Sesión</MenuItem>
            <MenuItem to={'createAccount'}>Registrarse</MenuItem>
          </>
        )}
      </Menu>
    </HeaderContainer>
  )
}
