import styled from 'styled-components'
import { Link } from 'react-router-dom'

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(22, 22, 23);
  padding: 10px 10%;
`
const Menu = styled.nav`
  display: flex;
  gap: 25px;
  align-items: center;
`
const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
  border-bottom: 5px solid transparent;
  &:hover {
    border-bottom: 5px solid #9500ff;
    padding: 27px 0px;
  }
`
const Divider = styled.hr`
  border-color: #ffffff8c;
  height: 30px;
`

export { HeaderContainer, Menu, MenuItem, Divider }
