import React from 'react'
import ApplicationRoutes from './routes'
import Header from './components/header'
import CustomAnonymization from './pages/CustomAnonymization'
import SnackbarError from './components/snackbarError'

function App() {
  return (
    <div
      style={{
        height: '100vh',
        // display: 'grid',
        // gridTemplateRows: '1fr auto',
      }}
    >
      <Header />
      <SnackbarError />
      <ApplicationRoutes />
      {/* <div
        style={{
          padding: '10px',
          background: 'rgba(22, 22, 23)',
          color: '#FFFFFF',
          fontFamily: 'Roboto, sans-serif',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        © Anonymizer - 2024
      </div> */}
    </div>
  )
}

export default App
